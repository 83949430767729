import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	protected currentState: string = "start";

	public currentSession: any = null;

	protected timeWasteSessions = [];

	public liveTimer: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public livePoints: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	constructor() {

	}

	protected run(): void {
		const wasteMS = !this.currentSession ? 0 : (new Date().getTime()) - this.currentSession.start.getTime();
		this.liveTimer.next(wasteMS);
		const points = Math.floor(wasteMS/100)/100;
		if(points>this.livePoints.value){
			this.livePoints.next(points);
		}
		if(!this.currentSession){
			this.livePoints.next(0);
		}
		window.requestAnimationFrame((t) => {
			this.run();
		});
	}

	ngOnInit(): void {
		window.addEventListener("focus", (ev: FocusEvent) => {
			if (["start", "focus"].includes(this.currentState) && this.currentSession) {
				// illegal state change
				console.log(`Illegal state transition from ${this.currentState} to focus`);

				this.currentSession.end = new Date();
				this.currentSession.illegal = true;
				this.timeWasteSessions.push(this.currentSession);
			} else {

			}
			this.currentSession = {
				start: new Date(),
				end: null,
				type: "default",
				points: 0,
				illegal: false
			};
			console.log("focus");
			this.currentState = "focus";
		});
		window.addEventListener("blur", (ev: FocusEvent) => {
			if (["start", "focus"].includes(this.currentState)) {
				this.currentSession.end = new Date();
				this.currentSession.points = this.livePoints.value;
				this.timeWasteSessions.push(this.currentSession);
				this.currentSession = null;
			} else {
				console.log(`Illegal state transition from ${this.currentState} to blur`);
			}
			console.log("blur");
			this.currentState = "blur";
		});

		// create initial session
		this.currentSession = {
			start: new Date(),
			end: null,
			type: "default",
			points: 0,
			illegal: false
		};
		// window.focus();

		this.run();
	}
}
